import React, {useEffect} from 'react';
import s from "./Modal.module.scss"
import axios from "axios";
import swal from 'sweetalert';

const Modal = ({isOpen, setIsOpen}) => {

    const [name, setName] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [errors, setErrors] = React.useState({})
    const [loading, setLoading] = React.useState(false)

    const changePhone = (value) => {
        const inputPhoneNumber = value;
        const phoneRegex = /^$|^[0-9+]+$/;
        const isValidPhoneNumber = phoneRegex.test(inputPhoneNumber);
        if (isValidPhoneNumber) {
            setPhone(inputPhoneNumber);
        }
    }

    const onSubmit = () => {
        const data = {name, phone, email, message}
        setLoading(true)
        axios.post('https://greenerfintech.ru/api/notifications', data).then(res => {
            setIsOpen(false)
            swal("Успешно!", "Ваша заявка успешно отправлена, ожидайте ответа в ближайшее время", "success");
            setLoading(false)
            setErrors({})
            setName("")
            setPhone("")
            setEmail("")
            setMessage("")
        }).catch(err => {
            setErrors(err.response.data.errors)
            setLoading(false)
        })
    }



    if (!isOpen) {
        return null
    }

    return (
        <div className={s.modal} onClick={() => setIsOpen(false)}>
            <div className={s.modalContent} onClick={e => e.stopPropagation()}>
                <div className={s.contentWrap}>
                    <div className={s.closeIcon} onClick={() => setIsOpen(false)}>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L14 14" stroke="black"/>
                            <path d="M14 1L1 14" stroke="black"/>
                        </svg>
                    </div>
                    <div className={s.logo}>
                        <img src={require("../../assets/logo.png")} alt="logo"/>
                    </div>
                    <div className={s.inputWrap}>
                        <input type="text" placeholder={"Имя"} name={"name"} value={name} onChange={(e) => setName(e.target.value)}/>
                        {errors.name && <div className={s.error}>{errors.name}</div>}
                    </div>
                    <div className={s.inputWrap}>
                        <input type="text" placeholder={"Почта"} name={"email"} value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                        {errors.email && <div className={s.error}>{errors.email}</div>}
                    </div>
                    <div className={s.inputWrap}>
                        <input type="text" placeholder={"Телефон"} name={"phone"} value={phone}
                               onChange={(e) => changePhone(e.target.value)}/>
                        {errors.phone && <div className={s.error}>{errors.phone}</div>}
                    </div>
                    <div className={s.inputWrap}>
                        <textarea name="message" placeholder={"ОПИСАНИЕ"} value={message}
                                  onChange={(e) => setMessage(e.target.value)}></textarea>
                        {errors.message && <div className={s.error}>{errors.message}</div>}
                    </div>
                    <div className={s.center}>
                        {!loading ?
                            <div className="btn" onClick={onSubmit}>
                                ОТПРАВИТЬ
                            </div> :
                            <img src={require("../../assets/loading2.gif")} alt="" style={{width: "50px"}}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
