import React from 'react';
import s from "./Footer.module.scss"
import pdf1 from "../../assets/ПОЛИТИКА_В_ОТНОШЕНИИ_ОБРАБОТКИ_ПЕРСОНАЛЬНЫХ_ДАННЫХ_ООО_ГРИНЕР.pdf"
import pdf2 from "../../assets/Оферта-_для-физ-лица_.pdf"
import pdf3 from "../../assets/Договор-ИТВ-_оферта-для-юр.л_.pdf"

const Footer = () => {
    return (
        <footer className={s.footer}>
            <div className="container">
                <div className={s.wrap}>
                    <div >
                        <img src={require("../../assets/logo.png")} alt="lgo" className={s.logo}/>
                    </div>
                    <div className={s.links}>
                        <a href={pdf1} target='_blank' rel='noopener noreferrer'>
                            Пользовательское соглашение
                        </a>
                        <a href={pdf2} target={"_blank"}>
                            Оферта для физ. лица
                        </a>
                        <a href={pdf3} target={"_blank"}>
                            Оферта для Юр. лиц
                        </a>
                    </div>
                    <div className={s.number}>
                        <a href="tel::+74958709238">+74958709238</a>
                    </div>
                </div>
                <div className={s.wrap + " " + s.mt60}>
                    <div className={s.text + " noneMobile " + s.firstChild}>
                        © 2023 Greener, Все права защищены
                    </div>
                    <div className={s.text}>
                        Вся информация на сайте носит справочный характер и не является публичной офертой, согласно
                        положениями ст.437 Гражданского кодекса Российской Федерации
                    </div>
                    <div className={s.text + " mobile " + s.firstChild}>
                        © 2023 Greener, Все права защищены
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
