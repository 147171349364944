import React, {useState} from 'react';
import Home from "../../components/Main/Home/Home";
import Transfer from "../../components/Main/Transfer/Transfer";
import PersonalArea from "../../components/Main/PersonalArea/PersonalArea";
import CrossBorderTransfers from "../../components/Main/CrossBorderTransfers/CrossBorderTransfers";
import Verification from "../../components/Main/Verification/Verification";
import Modal from "../../components/Modal/Modal";

const MainPage = ({isOpen, setIsOpen}) => {


    return (
        <>
            <Home setIsOpen={setIsOpen}/>
            <Transfer setIsOpen={setIsOpen}/>
            <PersonalArea setIsOpen={setIsOpen}/>
            <CrossBorderTransfers setIsOpen={setIsOpen}/>
            <Verification setIsOpen={setIsOpen}/>
            <Modal setIsOpen={setIsOpen} isOpen={isOpen}/>
        </>
    );
};

export default MainPage;
