import React from 'react';
import s from "./CrossBorderTransfers.module.scss"
import GreenBgWrap from "../../GreenBgWrap/GreenBgWrap";

const CrossBorderTransfers = ({setIsOpen}) => {
    return (
        <section className={s.crossBorderTransfers}>
            <div className="container">
                {/*<div className={s.title}>*/}
                {/*    Для оплаты кредита по свободным реквизитам необходимо ввести: <br/>*/}
                {/*    <span>БИК/название Банка, номер счета, сумму, ФИО держателя счета <br/> и детали платежной карты</span>*/}
                {/*</div>*/}
                <div className={s.wrap + " mobile"}>
                    <img src={require("../../../assets/mapImg.png")} alt="mapImg" className={s.mapImg}/>
                </div>
                <div className="title" style={{textAlign: "center"}}>
                    <span>Трансграничные</span> переводы
                </div>
                <div className={s.wrap}>
                    <img src={require("../../../assets/mapImg.png")} alt="mapImg" className={s.mapImg + " noneMobile"}/>
                    <div className={s.textContent}>
                        <div className={s.textItem}>
                            <div className={s.textItemTitle}>
                                Страны партнёры:
                            </div>
                            <div className={s.textItemItems}>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/uzbekistanImg.png")} alt="uzbekistan"/>
                                    Узбекистан
                                </div>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/kyrgiziaImg.png")} alt="kyrgiziaImg"/>
                                    Киргизия
                                </div>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/tadjicistanImg.png")} alt="tadjicistanImg"/>
                                    Таджикистан
                                </div>
                            </div>
                        </div>
                        <div className={s.textItem}>
                            <div className={s.textItemTitle}>
                                Зона расширения:
                            </div>
                            <div className={s.textItemItems}>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/azeybarjanImg.png")} alt="uzbekistan"/>
                                    Азербайджан
                                </div>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/kazakstanImg.png")} alt="kazahstan"/>
                                    Казахстан
                                </div>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/armeniaImg.png")} alt="armeia"/>
                                    Армения
                                </div>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/belarusImg.png")} alt="belarus"/>
                                    Беларусь
                                </div>
                                <div className={s.textItemItem}>
                                    <img src={require("../../../assets/moldovaImg.png")} alt="moldovaImg"/>
                                    Молдова
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.text + " " + s.row}>
                    Опыт и технологические решения по построению систем по переводам <br className="noneMobile"/> и
                    выдаче наличных на территории СНГ
                </div>
            </div>
            <GreenBgWrap marginTop={80}>
                <div className={s.greenBgWrap}>
                    <div className={s.greenBgTitle}>
                        <span>Кастомизированная разработка</span> <br/>
                        всего комплекса программного обеспечения
                    </div>
                    <div className={s.allDeviceImg}>
                        <img src={require("../../../assets/allDeviceImg.png")} alt="allDeviceImg"/>
                    </div>
                    <div className={s.textRow + " noneMobileFlex"}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#6A64FF"/>
                        </svg>
                        Создание mobile - и web - приложений
                        <div className={s.textRow} style={{marginTop: 0}}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#6A64FF"/>
                            </svg>
                            Сервисная поддержка и доработки под индивидуальное потребности
                        </div>
                        <div className={s.textRow} style={{marginTop: 0}}>
                            заказчика
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#6A64FF"/>
                            </svg>
                            Подключение дополнительных сервисов
                        </div>
                    </div>
                    <div className={s.textRow + " mobileFlex"}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#6A64FF"/>
                        </svg>
                        <div style={{flex: 1}}>Создание mobile - и web - приложений</div>
                    </div>
                    <div className={s.textRow+ " mobileFlex"} style={{marginTop: "15px"}}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#6A64FF"/>
                        </svg>
                        <div style={{flex: 1}}>Сервисная поддержка и доработки под индивидуальное потребности
                            заказчика
                        </div>
                    </div>
                    <div className={s.textRow+ " mobileFlex"} style={{marginTop: "15px"}}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="#6A64FF"/>
                        </svg>
                        <div style={{flex: 1}}> Подключение дополнительных сервисов</div>
                    </div>
                    <div className={s.center}>
                        <div className="btn" onClick={() => setIsOpen(true)}>
                            Заказать консультацию
                        </div>
                    </div>
                </div>
            </GreenBgWrap>
        </section>
    );
};

export default CrossBorderTransfers;
