import logo from './logo.svg';
import './App.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MainPage from "./pages/MainPage/MainPage";
import {useState} from "react";

function App() {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <Header setIsOpen={setIsOpen} isOpen={isOpen}/>
            <MainPage isOpen={isOpen} setIsOpen={setIsOpen}/>
            <Footer/>
        </>
    );
}

export default App;
