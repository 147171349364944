import React from 'react';
import s from "./GreenBgWrap.module.scss"

const GreenBgWrap = ({children, marginTop, height}) => {
    return (
        <div className={s.bg} style={{marginTop: marginTop ? `${marginTop}px` : 0, height: height ? `${height}px` : "100%"}}>
            <div className="container">
                {children}
            </div>
        </div>
    );
};

export default GreenBgWrap;
